import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { UserSettingsService } from './user-settings.service';
import { IconName } from '@awesome.me/kit-62bca205da/icons';

export interface SideMenuItem {
  name: string;
  icon: IconName;
  link: string;
  permission?: string;
  useExact?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public isOpen: boolean = false;
  public iconsOnly: boolean = false;
  public fullMenu: boolean = true;
  public sideMenu: SideMenuItem[] = [];
  public styles = {
    width: {
      current: 200,
      default: 200,
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private settingsService: UserSettingsService,
  ) {
    this.loadSidebarSettings();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let active = this.route;
        while (active.firstChild) {
          active = active.firstChild;
        }
        active.data.subscribe((data: Data) => {
          if (data['sidenav']) {
            this.open();
          } else {
            this.close();
          }
        });
      });
  }

  setMenu(menu: SideMenuItem[]): void {
    this.sideMenu = menu;
  }

  private saveSidebarSettings() {
    this.settingsService.globalSidebarIconsOnly = this.iconsOnly;
  }

  private loadSidebarSettings() {
    this.iconsOnly = this.settingsService.globalSidebarIconsOnly;
  }

  setIconVisibility(iconsOnly: boolean): void {
    this.iconsOnly = iconsOnly;
    this.saveSidebarSettings();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  open(): void {
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }
}
